"use client";

import React from "react";
import { Progress } from "@radix-ui/react-progress";

type LoadingProps = {
  message?: string;
  value?: number;
};

const Loading: React.FC<LoadingProps> = ({
  message = "Loading, please wait...",
  value = 50,
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4">
      <p className="text-gray-600 text-lg font-medium mb-4">{message}</p>
      <div className="w-full max-w-md">
        <Progress value={value} />
      </div>
    </div>
  );
};

export default Loading;
