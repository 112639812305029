"use client";

import React from "react";
import Container from "./components/Container";
import Hero from "./components/Hero";
import VisitingJapanBlogSection from "./sections/VisitingJapanBlogSection";
import LivingInJapanBlogSection from "./sections/LivingInJapanBlogSection";
import HomePageBannerCarousel from "./sections/HomepageBannerCarousel";
import LivingInJapanGuide from "./sections/LivingInJapanGuide";
import VisitingJapanGuide from "./sections/VisitingJapanGuide";

export default function HomePage() {
  return (
    <>
      <Hero />
      <Container large>
        <section id="visiting-japan">
          <VisitingJapanGuide />
          <HomePageBannerCarousel />
          <VisitingJapanBlogSection />
        </section>
        <section id="living-in-japan">
          <LivingInJapanGuide />
          <LivingInJapanBlogSection />
        </section>
      </Container>
    </>
  );
}
