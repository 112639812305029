"use client";

import { TypographyH2 } from "../../components/Typography/TypographyH2";
import { TypographyP } from "../../components/Typography/TypographyP";
import AllBlogs from "../../components/AllBlogs";

const LivingInJapanBlogSection: React.FC = () => {

  return (
    <main>
      <section className="mt-10 text-center">
        <TypographyH2>Pinoy sa Japan Blogs</TypographyH2>
        <TypographyP>Practical tips for living comfortably from Filipinos na nandito na</TypographyP>
      </section>
      <AllBlogs
        numberOfPosts={5}
        showAllButtonDestination="/pages/all-blogs"
        filterCategories={["living-in-japan"]}
      />
    </main>
  );
}

export default LivingInJapanBlogSection;
