import { Button } from "@/components/ui/button";

export default function Hero() {
  return (
    <>
      {/* Hero */}
      <div className="relative overflow-hidden h-[75vh] w-full">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 object-cover w-full h-full z-0"
          src="https://storage.googleapis.com/photo-portfolio/tara-japan/tara-japan-hero-clip.mov"
        ></video>
        <div className="absolute inset-0 bg-black opacity-50 z-1"></div>
        {/* Gradients */}
        <div
          aria-hidden="true"
          className="flex absolute -top-96 start-1/2 transform -translate-x-1/2 z-2"
        >
          <div className="bg-gradient-to-r from-background/50 to-background blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="w-[90rem] h-[50rem] rounded-full origin-top-left -rotate-12 -translate-x-[15rem] from-primary-foreground via-primary-foreground to-background" />
        </div>
        {/* End Gradients */}
        <div className="relative z-10">
          <div className="container mt-40 lg:py-16 h-full flex items-center justify-center">
            <div className="max-w-2xl text-center mx-auto dark:text-slate-600 my-auto">
              <div className="mt-5 max-w-2xl">
                <h1 className="scroll-m-20 text-4xl font-bold tracking-tight lg:text-5xl text-white">
                  Japan, Made Easy for Filipinos.
                </h1>
              </div>
              <div className="mt-5 max-w-3xl">
                <p className="text-xl text-slate-200">Start Your Journey</p>
              </div>
              <div className="mt-8 gap-3 flex justify-center">
                <a href="#visiting-japan">
                  <Button size="lg">Visiting Japan</Button>
                </a>
                <a href="#living-in-japan">
                  <Button size="lg" variant="secondary">
                    Living Here
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
