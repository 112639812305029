"use client";

import { TypographyH2 } from "../../components/Typography/TypographyH2";
import { TypographyP } from "../../components/Typography/TypographyP";
import AllBlogs from "../../components/AllBlogs";

const VisitingJapanBlogSection: React.FC = () => {

  return (
    <main className="mt-10">
      <section className="mt-10 text-center">
        <TypographyH2>Pinoy Japan Travel Blogs</TypographyH2>
        <TypographyP>Filipino friendly guides, tips and helpful advice from fellow travellers</TypographyP>
      </section>
      <AllBlogs
        numberOfPosts={5}
        showAllButtonDestination="/pages/all-blogs"
        filterCategories={["visiting-japan"]}
      />

    </main>
  );
}

export default VisitingJapanBlogSection;
