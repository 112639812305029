"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { urlForImage } from "@/lib/sanity/image";
import CategoryLabel from "./blog/category";

type BlogCardProps = {
  post: {
    _id: string;
    title: string;
    excerpt?: string;
    mainImage?: {
      asset?: {
        _ref?: string;
      };
    };
    slug: {
      current: string;
    };
    categories: {
      slug: {
        current: string;
      };
      color: string;
      title: string;
    }[];
  };
  className?: string;
};

const BlogCard: React.FC<BlogCardProps> = ({ post, className = "" }) => {
  return (
    <Card className={`transition-all duration-300 hover:shadow-lg hover:scale-105 ${className}`}>
      <CardHeader>
        <Link
          href={`/post/${post.slug.current}`}
          aria-label={`Read more about ${post.title}`}
        >
          {post.mainImage?.asset?._ref && (
            <div
              className="relative w-full h-0"
              style={{ paddingTop: "66.66%" }}
            >
              <Image
                src={urlForImage(post.mainImage)?.src || "/default-image.png"}
                alt={post.title}
                fill
                className="object-cover rounded-t-lg"
              />
            </div>
          )}
        </Link>
        <CategoryLabel categories={post.categories} nomargin={true} />
      </CardHeader>
      <CardContent className="p-4">
        <Link
          href={`/post/${post.slug.current}`}
          aria-label={`Read more about ${post.title}`}
        >
          <CardTitle className="mb-2">{post.title}</CardTitle>
        </Link>
        {post.excerpt && <CardDescription>{post.excerpt}</CardDescription>}
      </CardContent>
      <CardFooter>
        <Link
          href={`/post/${post.slug.current}`}
          className="text-primary hover:text-red-200 transition-colors text-xs underline"
        >
          Read more
        </Link>
      </CardFooter>
    </Card>
  );
};

export default BlogCard;
