"use client"

import React from 'react';
import Link from "next/link";
import Image from "next/image";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";

type BannerData = {
  _id: string;
  title: string;
  imgSrc: string;
  linkHref?: string;
  alt?: string;
}

interface BannerCarouselProps {
  banners: BannerData[];
  itemHeight?: string;
}

const BannerCarousel: React.FC<BannerCarouselProps> = ({ banners, itemHeight }) => {
  return (
    <Carousel autoPlayInterval={3} className="relative">
      <CarouselContent>
        {banners?.map((banner) => (
          <CarouselItem
            key={banner._id}
            className={`relative overflow-hidden w-full ${itemHeight} transition-transform duration-500 ease-in-out shadow-lg`}
          >
            <Link href={banner.linkHref || '#'}>
              <Image
                src={banner.imgSrc}
                alt={banner.alt || banner.title}
                quality={100}
                layout="fill"
                className="absolute inset-0 object-cover w-full h-full rounded-md"
              />
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-1.5 rounded-md text-center text-slate-700 text-sm md:text-md bg-slate-200 bg-opacity-50">
                {banner.title}
              </div>
            </Link>
          </CarouselItem>
        ))}
      </CarouselContent>
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {Array.from({ length: banners.length }).map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full transition-colors duration-300 ${index === 0 ? "bg-primary" : "bg-gray-200"
              }`}
          />
        ))}
      </div>
    </Carousel>
  );
}

export default BannerCarousel;
