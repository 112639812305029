"use client";

import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import BlogCard from "../components/BlogCard";
import { getAllPosts } from "@/lib/sanity/client";
import Loading from "./Loading";

type Post = {
  _id: string;
  title: string;
  excerpt?: string;
  mainImage?: {
    asset?: {
      _ref?: string;
    };
  };
  slug: {
    current: string;
  };
  categories: {
    slug: {
      current: string;
    };
    color: string;
    title: string;
  }[];
};

type AllBlogsProps = {
  filterCategories?: string[];
  numberOfPosts?: number;
  showAllButtonDestination?: string;
};

const AllBlogs: React.FC<AllBlogsProps> = ({
  filterCategories = [],
  numberOfPosts = 8,
  showAllButtonDestination = "/archive",
}) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchPosts() {
      try {
        setLoading(true);
        const data = await getAllPosts();
        setPosts(data || []);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchPosts();
  }, []);

  // Filter posts by categories
  const filteredPosts = filterCategories.length
    ? posts.filter((post) =>
      post.categories.some((category) =>
        filterCategories.includes(category.slug.current)
      )
    )
    : posts;

  // Limit posts based on numberOfPosts prop
  const limitedPosts = filteredPosts.slice(0, numberOfPosts);

  // Featured posts (first two)
  const featuredPosts = limitedPosts.slice(0, 2);

  // Remaining posts
  const otherPosts = limitedPosts.slice(2);

  return (
    <Container className="py-10">
      {loading ? (
        <Loading />
      ) : limitedPosts && limitedPosts.length > 0 ? (
        <div>
          {/* Featured posts (two-column layout on desktop) */}
          <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
            {featuredPosts.map((post) => (
              <BlogCard key={post._id} post={post} />
            ))}
          </div>

          {/* Other posts (grid layout) */}
          {otherPosts.length > 0 && (
            <div className="mt-10 grid gap-6 md:grid-cols-3 grid-cols-1">
              {otherPosts.map((post) => (
                <BlogCard key={post._id} post={post} />
              ))}
            </div>
          )}

          {/* Show All Posts Button */}
          <div className="mt-10 flex justify-center">
            <a
              href={showAllButtonDestination}
              className="relative inline-flex items-center gap-1 rounded-md border border-gray-300 bg-white px-3 py-2 pl-4 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-500 dark:bg-gray-800 dark:text-gray-300"
            >
              Show All Blogs
            </a>
          </div>
        </div>
      ) : (
        <p>No posts available</p>
      )}
    </Container>
  );
};

export default AllBlogs;
