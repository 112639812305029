import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";

interface Hero2Props {
  title: string;
  subtitle: string;
  button1Text?: string;
  button1Url?: string;
  button2Text?: string;
  button2Url?: string;
  imageSrc: string;
  imageAlt: string;
  imagePosition?: "left" | "right";
}

export default function Hero2({
  title,
  subtitle,
  button1Text,
  button1Url,
  button2Text,
  button2Url,
  imageSrc,
  imageAlt,
  imagePosition = "right",
}: Hero2Props) {
  const isImageLeft = imagePosition === "left";

  // Helper function to determine if a URL is external
  const isExternal = (url: string) => /^https?:\/\//.test(url);

  return (
    <section className="relative w-full bg-white dark:bg-gray-900">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between py-12 lg:py-24 px-6 lg:px-12 gap-8">
        {/* Conditional Layout: Image Left */}
        {isImageLeft && (
          <div className="w-full lg:w-1/2 h-[250px] sm:h-[300px] lg:h-[450px] relative">
            <Image
              src={imageSrc}
              alt={imageAlt}
              layout="responsive"
              width={700}
              height={450}
              objectFit="cover"
              priority
              quality={90}
              className="rounded-lg shadow-lg"
            />
          </div>
        )}

        {/* Text Section */}
        <div className="w-full lg:w-1/2 text-center lg:text-left">
          <h2 className="scroll-m-20 text-3xl lg:text-5xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
            {title}
          </h2>
          <p className="text-lg lg:text-xl text-gray-600 dark:text-gray-400 max-w-md mb-6 mx-auto lg:mx-0">
            {subtitle}
          </p>
          <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
            {/* Button 1 */}
            {button1Text && button1Url && (
              <>
                {isExternal(button1Url) ? (
                  <a href={button1Url} target="_blank" rel="noopener noreferrer">
                    <Button size="lg">{button1Text}</Button>
                  </a>
                ) : (
                  <Link href={button1Url} passHref>
                    <Button size="lg">{button1Text}</Button>
                  </Link>
                )}
              </>
            )}
            {/* Button 2 */}
            {button2Text && button2Url && (
              <>
                {isExternal(button2Url) ? (
                  <a href={button2Url} target="_blank" rel="noopener noreferrer">
                    <Button size="lg" variant="secondary">
                      {button2Text}
                    </Button>
                  </a>
                ) : (
                  <Link href={button2Url} passHref>
                    <Button size="lg" variant="secondary">
                      {button2Text}
                    </Button>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>

        {/* Image on Right */}
        {!isImageLeft && (
          <div className="w-full lg:w-1/2 h-[250px] sm:h-[300px] lg:h-[450px] relative">
            <Image
              src={imageSrc}
              alt={imageAlt}
              layout="responsive"
              width={700}
              height={450}
              objectFit="cover"
              priority
              quality={90}
              className="rounded-lg shadow-lg"
            />
          </div>
        )}
      </div>
    </section>
  );
}
