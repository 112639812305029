"use client";

import Hero2 from "../components/Hero2";

const LivingInJapanGuide: React.FC = () => {

  return (
    <main>
      <section>
        <Hero2
          title="Japan Life Hacks"
          subtitle="Life in Japan, Pinoy Style"
          button1Text="Explore"
          button1Url="/pages/living-in-japan"
          imageSrc="https://storage.googleapis.com/photo-portfolio/tara-japan/woman-waiting-for-train.jpg"
          imageAlt="Picture of woman waiting for a train"
          imagePosition="left"
        />
      </section>
    </main>
  );
};

export default LivingInJapanGuide;
