"use client";

import Hero2 from "../components/Hero2";

const VisitingJapanGuide: React.FC = () => {

  return (
    <main>
      <section>
        <Hero2
          title="Tara, explore Japan!"
          subtitle="Less hassle: lahat ng kailangan for that big trip, find it here!"
          button1Text="Explore"
          button1Url="/pages/visiting-japan"
          imageSrc="https://storage.googleapis.com/photo-portfolio/tara-japan/asakusa.jpg"
          imageAlt="Picture of tourists in Asakusa Japan"
          imagePosition="right"
        />
      </section>
    </main>
  );
};

export default VisitingJapanGuide;
