"use client"

import React, { useEffect, useState } from 'react';
import BannerCarousel from "../../components/BannerCarousel";
import { getBanners } from "@/lib/sanity/client";
import { TypographyH2 } from "../../components/Typography/TypographyH2";
import { urlForImage } from "@/lib/sanity/image";

const HomePageBannerCarousel: React.FC = () => {
  const [banners, setBanners] = useState<BannerData[]>([]);

  type BannerData = {
    _id: string;
    title: string;
    imgSrc: string;
    linkHref?: string;
    alt?: string;
  }

  useEffect(() => {
    async function fetchBanners() {
      const data = await getBanners();
      const sanitizedData = data.map((banner: any) => ({
        _id: banner._id,
        title: banner.title,
        imgSrc: urlForImage(banner.imgSrc),
        linkHref: banner.linkHref || '#',
        alt: banner.alt,
      }));
      setBanners(sanitizedData);
    }
    fetchBanners();
  }, []);

  return (
    <main className="my-10">
      <TypographyH2 className="text-center mb-10">Featured Destinations</TypographyH2>
      <BannerCarousel banners={banners} itemHeight={"h-40 md:h-60"} />
    </main>
  );
}

export default HomePageBannerCarousel;
